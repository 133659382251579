.nn_tm_skills_wrap,
.nn_tm_skills_wrap .inner_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_skills_wrap {
  background-color: #f6f7fd;
  padding: 90px 0px;
  margin-bottom: 100px;
}
.nn_tm_skills_wrap .inner_wrap {
  display: flex;
}
.nn_tm_skills_wrap .leftbox {
  width: 35%;
  height: auto;
  clear: both;
  float: left;
  padding-right: 0px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_skills_wrap .rightbox {
  width: 65%;
  height: auto;
  clear: both;
  float: right;
  padding-left: 65px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.progress_bar_wrap_total {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 10px;
  position: relative;
  margin-top: -4px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_progress_wrap[data-size='big'] .nn_tm_progress .nn_tm_bar_bg {
  height: 40px;
}
.nn_tm_progress_wrap[data-size='small'] .nn_tm_progress .nn_tm_bar_bg {
  height: 5px;
}

.nn_tm_progress_wrap[data-round='a'] .nn_tm_progress .nn_tm_bar_bg,
.nn_tm_progress_wrap[data-round='a'] .nn_tm_progress .nn_tm_bar {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.nn_tm_progress_wrap[data-round='b'] .nn_tm_progress .nn_tm_bar_bg,
.nn_tm_progress_wrap[data-round='b'] .nn_tm_progress .nn_tm_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.nn_tm_progress_wrap[data-round='c'] .nn_tm_progress .nn_tm_bar_bg,
.nn_tm_progress_wrap[data-round='c'] .nn_tm_progress .nn_tm_bar {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
.nn_tm_progress {
  width: 100%;
  margin-bottom: 16px;
}

.nn_tm_progress:last-child {
  margin-bottom: 0px;
}
.nn_tm_progress > span {
  margin: 0px 0px 3px 0px;
  width: 100%;
  display: block;
  text-align: left;
}
.nn_tm_progress span.label {
  font-size: 13px;
  font-weight: 700;
  color: #000;
  font-family: 'Montserrat';
}
.nn_tm_progress span.label .experience {
  font-size: 12px;
  font-weight: 600;
}
.nn_tm_progress span.number {
  float: right;
  font-size: 13px;
  color: #000;
  font-weight: 700;
  font-family: 'Montserrat';
}
.nn_tm_progress .nn_tm_bar_bg {
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 100%;
  position: relative;
  height: 20px;
}
.nn_tm_progress .nn_tm_bar_bg .nn_tm_bar_wrap {
  width: 0px;
  height: 100%;
}
.nn_tm_progress .nn_tm_bar_bg .nn_tm_bar_wrap.open {
  -webkit-animation: nn_tm_expand 3s cubic-bezier(0.165, 0.84, 0.44, 1); /* Safari 4+ */
  -moz-animation: nn_tm_expand 3s cubic-bezier(0.165, 0.84, 0.44, 1); /* Fx 5+ */
  animation: nn_tm_expand 3s cubic-bezier(0.165, 0.84, 0.44, 1); /* IE 10+ */
  width: 100%;
}
.nn_tm_progress .nn_tm_bar_bg .nn_tm_bar {
  height: 100%;
  background: #333;
  width: 0px;
  overflow: hidden;
}
.nn_tm_progress_wrap[data-strip='on'] .nn_tm_progress .nn_tm_bar {
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 75%,
    transparent 75%,
    transparent
  );

  -webkit-animation: nn_tm_bar_move 4s linear infinite;
  -moz-animation: nn_tm_bar_move 4s linear infinite;
  animation: nn_tm_bar_move 4s linear infinite;
  background-size: 50px 50px;
}

@-webkit-keyframes nn_tm_bar_move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes nn_tm_bar_move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@keyframes nn_tm_bar_move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

/*expand width animation*/
@-webkit-keyframes nn_tm_expand {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes nn_tm_expand {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes nn_tm_expand {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.nn_tm_mini_title_holder {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_mini_title_holder h4 {
  line-height: 1;
  margin-bottom: 18px;
}
.nn_tm_mini_title_holder.contact h4 {
  margin-bottom: 30px;
}

.nn_tm_mini_title_holder h4 strong {
  color: #e3872d;
}
