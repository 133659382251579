.nn_tm_experience_wrapper_all {
  margin-top: 70px;
}

.resume-section h2,
h3,
h4 {
  color: #40424a;
  margin: 0;
  font-weight: 600;
  font-family: 'Josefin Sans', sans-serif;
}

.resume-section h2 {
  font-size: 48px;
}

.resume-list h3 {
  font-size: 30px;
}

.resume-list p {
  font-size: 18px;
  color: #808181;
  line-height: 1.8;
}

.resume-list {
  padding: 0;
  margin: 0;
}

/*---------------------
	Helper CSS
-----------------------*/
.spad {
  padding-top: 175px;
  padding-bottom: 110px;
}

.section-title h2 {
  display: inline-block;
  position: relative;
  margin-bottom: 110px;
  padding-bottom: 2px;
  line-height: normal;
}

.section-title h2:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #40424a;
}

/*---------------------
	EXPERIENCE
----------------------*/

.resume-list {
  list-style: none;
  margin-left: 60px;
  padding-left: 110px;
  border-left: 2px solid #40424a;
}

.resume-list li {
  margin-bottom: 120px;
  position: relative;
}

.resume-list li:last-child {
  margin-bottom: 0;
}

.resume-list li:after {
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  border: 2px solid #40424a;
  border-radius: 50px;
  background: #cacaca;
  top: 30px;
  left: -120px;
}

.resume-list h2 {
  font-size: 54px;
  margin-bottom: 10px;
}

.resume-list h4 {
  font-size: 16px;
  text-transform: uppercase;
  color: #808181;
  margin-top: 10px;
}

.resume-list p {
  margin-bottom: 0;
}

/* ===========================
  Responsive
==============================*/
@media only screen and (max-width: 1730px) {
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1450px) {
}

@media only screen and (max-width: 1366px) and (min-width: 1200px) {
}

/* Medium screen : 992px. */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Tablet :768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large Mobile :480px. */
@media only screen and (max-width: 767px) {
  .resume-list {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }
}

/* small Mobile :320px. */
@media only screen and (max-width: 479px) {
  .resume-list h2 {
    font-size: 50px;
  }
}
