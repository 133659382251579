/*---------------------------------------------------*/
/*	MEDIA QUERIES 
/*---------------------------------------------------*/
@media (max-width: 1400px) {
  .nn_tm_about_wrap .leftbox {
    padding-right: 0px;
  }
}
@media (max-width: 1200px) {
  .nn_tm_services_wrap .list_wrap ul li {
    width: 50%;
  }
  #nn_tm_popup_blog .close {
    right: 0px;
  }
  .nn_tm_list_wrap > ul.total > li {
    width: 50%;
  }
  .nn_tm_about_wrap .leftbox {
    display: none;
  }
  .nn_tm_about_wrap .rightbox {
    padding-left: 0px;
    width: 100%;
  }
  .nn_tm_contact_wrap_all .leftbox {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 19px;
  }
  .nn_tm_contact_wrap_all .rightbox {
    width: 100%;
    float: none;
  }
}
@media (max-width: 1040px) {
  .nn_tm_about_wrap {
    flex-direction: column;
  }
  .nn_tm_about_wrap .rightpart .info_list ul li {
    width: 100%;
  }
  .nn_tm_about_wrap .leftpart {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 60px;
  }
  .nn_tm_about_wrap .rightpart {
    width: 100%;
  }
  .nn_tm_skills_wrap .inner_wrap {
    flex-direction: column;
  }
  .nn_tm_skills_wrap .leftbox {
    padding-right: 0px;
    width: 100%;
    margin-bottom: 60px;
  }
  .nn_tm_skills_wrap .rightbox {
    width: 100%;
    padding-left: 0px;
  }
  .nn_tm_main_demos_list_wrap ul {
    margin: 0px;
  }
  .nn_tm_main_demos_list_wrap ul li {
    width: 100%;
    padding: 0px;
  }
  .nn_tm_portfolio_single_wrap .details_wrap {
    flex-direction: column;
  }
  .nn_tm_portfolio_single_wrap .details_wrap .leftbox {
    padding-right: 0px;
    width: 100%;
    margin-bottom: 50px;
  }
  .nn_tm_portfolio_single_wrap .details_wrap .rightbox {
    width: 100%;
  }
  .nn_tm_mobile_header_wrap {
    display: block;
  }
  .nn_tm_preloader {
    display: none;
  }
  .nn_tm_leftpart_wrap {
    display: none;
  }
  .nn_tm_rightpart {
    padding-left: 0px !important;
  }
  .nn_tm_wrapper_all .nn_tm_resize-right {
    opacity: 0 !important;
  }
}
@media (max-width: 768px) {
  .nn_tm_hero_header_wrap .name_holder h3 {
    font-size: 40px;
  }
  .nn_tm_hero_header_wrap .text_typing p {
    font-size: 21px;
  }
  .nn_tm_title_holder h3 {
    font-size: 45px;
  }
  .nn_tm_title_holder:before {
    top: -2px;
  }
  .nn_tm_services_wrap .list_wrap ul {
    margin: 0px;
  }
  .nn_tm_services_wrap .list_wrap ul li {
    width: 100%;
    padding-left: 0px;
  }
  .nn_tm_counter_list li {
    width: 50%;
  }
  .nn_tm_counter_list li .inner {
    padding: 45px 15px 48px 15px;
  }
  .nn_tm_portfolio_list > li {
    width: 50%;
  }
  .nn_tm_list_wrap > ul.total {
    margin: 0px;
  }
  .nn_tm_list_wrap > ul.total > li {
    width: 100%;
    padding-left: 0px;
  }
  .nn_tm_about_wrap .about_short_contact_wrap ul li {
    width: 100%;
  }
  .nn_tm_totop {
    right: 20px;
    bottom: 10px;
  }
  .nn_tm_totop.opened {
    bottom: 20px;
  }
  .nn_tm_preloader {
    display: none;
  }
  .nn_tm_portfolio_single_wrap .title_holder h3 {
    font-size: 45px;
  }
}
@media (max-width: 480px) {
  .nn_tm_hero_header_wrap .name_holder h3 {
    font-size: 30px;
  }
  .nn_tm_hero_header_wrap .text_typing p {
    font-size: 18px;
  }
  .nn_tm_title_holder:before {
    display: none;
  }
  .nn_tm_title_holder h3 {
    padding-left: 0px;
  }
  .nn_tm_title_holder span {
    padding-left: 0px;
  }
  .nn_tm_counter_list li {
    width: 100%;
  }
  .nn_tm_counter_list li .inner {
    padding: 0px 0px 35px 0px;
  }
  .nn_tm_portfolio_list {
    margin: 0px;
  }
  .nn_tm_portfolio_list > li {
    width: 100%;
    padding-left: 0px;
  }
  .nn_tm_counter_wrap {
    padding: 94px 0px 48px 0px;
  }
}
