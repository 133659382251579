.nn_tm_title_holder {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: left;
  padding-bottom: 100px;
  position: relative;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_title_holder.contact {
  padding-bottom: 93px;
}
.nn_tm_title_holder:before {
  position: absolute;
  content: '';
  width: 6px;
  height: 72px;
  background-color: #e3872d;
  top: 2px;
}
.nn_tm_title_holder h3 {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding-left: 25px;
  line-height: 1;
  /*	font-style: italic;*/
}
.nn_tm_title_holder span {
  font-size: 16px;
  font-family: 'Montserrat';
  padding-left: 25px;
}
.nn_tm_about_wrapper_all {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #fff;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.nn_tm_main_title_holder {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: left;
  margin-bottom: 120px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_main_title_holder.about {
  text-align: left;
  margin-bottom: 0px;
  padding: 0px;
}
.nn_tm_main_title_holder h3 {
  font-weight: 900;
  font-family: 'Montserrat';
  color: #041230;
  font-size: 30px;
  line-height: 1;
}
.nn_tm_main_title_holder h3 span {
  position: relative;
}
.nn_tm_main_title_holder h3 span:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 4px;
  background-color: #041230;
  letter-spacing: 0px;
  bottom: -10px;
}
.nn_tm_about_wrapper_all {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #fff;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_main_title_holder {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: left;
  margin-bottom: 120px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_main_title_holder.about {
  text-align: left;
  margin-bottom: 0px;
  padding: 0px;
}
.nn_tm_main_title_holder h3 {
  font-weight: 900;
  font-family: 'Montserrat';
  color: #041230;
  font-size: 30px;
  line-height: 1;
}
.nn_tm_main_title_holder h3 span {
  position: relative;
}
.nn_tm_main_title_holder h3 span:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 4px;
  background-color: #041230;
  letter-spacing: 0px;
  bottom: -10px;
}
.nn_tm_about_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-bottom: 122px;
  position: relative;
  margin-top: -6px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_about_wrap .author_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  display: flex;
  align-items: center;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_about_wrap .leftbox {
  width: 35%;
  float: left;
  height: auto;
  padding-right: 40px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_about_wrap .leftbox .about_image_wrap {
  position: relative;
}
.nn_tm_about_wrap .leftbox .about_image_wrap .image {
  position: absolute;
  z-index: 2;
}
.nn_tm_about_wrap .leftbox .about_image_wrap .image .inner {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.nn_tm_about_wrap .leftbox .about_image_wrap .border .inner {
  position: absolute;
  top: 25px !important;
  bottom: -25px !important;
  left: 25px !important;
  right: -25px !important;
  border: 10px solid #041230;
}
.nn_tm_about_wrap .leftbox .about_image_wrap img {
  opacity: 0;
}
.nn_tm_about_wrap .rightbox {
  width: 65%;
  float: right;
  padding: 0px 0px 0px 65px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_about_wrap .subtitle {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}
.nn_tm_about_wrap .subtitle p {
  font-family: 'Montserrat';
  color: #333;
  font-size: 15px;
  font-weight: 600;
}
.nn_tm_about_wrap .definition {
  margin-bottom: 15px;
}
.nn_tm_about_wrap .definition p {
  color: #333;
}
.nn_tm_about_wrap .definition p strong {
  color: #e3872d;
}

.nn_tm_about_wrap .about_short_contact_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 13px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_about_wrap .nn_tm_main_title_holder {
  margin-bottom: 40px;
}
.nn_tm_about_wrap .about_short_contact_wrap ul {
  margin: 0px;
  list-style-type: none;
}
.nn_tm_about_wrap .about_short_contact_wrap ul li {
  margin: 0px 0px 10px 0px;
  width: 50%;
  float: left;
}
.nn_tm_about_wrap .about_short_contact_wrap ul li:nth-of-type(2n) {
  clear: right;
}
.about_short_contact_wrap ul li label {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 13px;
  color: #000;
  min-width: 85px;
  display: inline-block;
}
.nn_tm_about_wrap .about_short_contact_wrap ul li a {
  text-decoration: none;
  color: #000;
  position: relative;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nn_tm_about_wrap .about_short_contact_wrap ul li a:hover {
  color: #bd0a0d;
}
.nn_tm_about_wrap .buttons_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_about_wrap .buttons_wrap ul {
  margin: 0px;
  list-style-type: none;
}
.nn_tm_about_wrap .buttons_wrap ul li {
  margin: 0px 30px 10px 0px;
  float: left;
}
.nn_tm_about_wrap .buttons_wrap ul li:last-child {
  margin-right: 0px;
}
.nn_tm_about_wrap .buttons_wrap ul li a span {
  position: relative;
  z-index: 2;
}
.nn_tm_about_wrap .buttons_wrap ul li a {
  text-decoration: none;
  color: #fff;
  background-color: #e3872d;
  font-weight: 600;
  font-family: 'Montserrat';
  padding: 12px 35px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.nn_tm_about_wrap .buttons_wrap ul li a:before {
  position: absolute;
  content: '';
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background-color: #041230;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: 1;
}
.nn_tm_about_wrap .buttons_wrap ul li a:hover:before {
  width: 180%;
}
