/*
This file is made by Marketify (2018)
This css file includes all base styles and template's main color (in this tempalte main color is pink(#f00a77)). 
Template's main color styles located at the bottom of this file.

01) BASE
02) FORM ELEMENTS
03) FONTELLO
04) SKELETON OR GRID
05) PERFECT SCROLLBAR
06) MAGNIFIC POPUP
07) NAV ANCHOR
08) ANIMATION
09) OWL CAROUSEL

*/

/*------------------------------------------------------------------*/
/*	01) BASE
/*------------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

blockquote {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: '';
  content: none;
}
ul,
ol {
  margin: 0px;
}
ul li,
ol li {
  margin-bottom: 0px;
}
img {
  vertical-align: middle;
  max-width: 100%;
}
iframe {
  max-width: 100%;
}
del {
  text-decoration: line-through;
}
a,
i {
  border: none;
}
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1;
  font-family: 'Montserrat';
}
h1 {
  font-size: 45px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

table {
  width: 100%;
  max-width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0px;
  margin-bottom: 15px;
  color: #999;
  border: 1px solid #eee;
}
table tr {
  border: 1px solid #eee;
}
table th {
  vertical-align: top;
  color: #333;
  background: none;
  font-size: 16px;
  font-weight: normal;
  padding: 15px 10px;
  border: 1px solid #eee;
}
table td {
  vertical-align: top;
  padding: 10px;
  border: 1px solid #eee;
}

code,
pre {
  padding: 10px;
  padding-bottom: 0;
  border-left: 3px solid #6fbf71;
  border-bottom: 1px solid transparent;
  background: #f7f7f7;
  overflow-x: scroll;
}

pre {
  display: block;
  word-break: break-all;
  word-wrap: break-word;
}
pre code {
  white-space: pre-wrap;
}

.space10 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 10px;
}
.space20 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 20px;
}
.space30 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 30px;
}
.space40 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 40px;
}
.space50 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 50px;
}
.space60 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 60px;
}
.space70 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 70px;
}
.space80 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 80px;
}
.space90 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 90px;
}
.space100 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 100px;
}
.space200 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 200px;
}
.space300 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 300px;
}
.space400 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 400px;
}
.space500 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 500px;
}
.space1000 {
  width: 100%;
  float: left;
  clear: both;
  margin-bottom: 1000px;
}

/*------------------------------------------------------------------*/
/*	02) FORM ELEMENTS
/*------------------------------------------------------------------*/

textarea {
  border-radius: 0;
  font-size: 13px;
  color: #333;
  resize: vertical;
  font-family: 'Open Sans';
  letter-spacing: 1.2px !important;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  -ms-box-sizing: border-box; /* IE 10 + */
  box-sizing: border-box; /* Opera/IE 8+ */

  line-height: 1.4;
  padding: 10px !important;
  width: 100%;
  border: 1px solid #eee;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'],
.uneditable-input {
  border-radius: 0;
  font-size: 13px;
  min-width: auto;
  color: #333;
  letter-spacing: 1.4px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  -ms-box-sizing: border-box; /* IE 10 + */
  box-sizing: border-box; /* Opera/IE 8+ */

  font-family: 'Open Sans';

  line-height: 1.6;
  padding: 10px !important;
  height: 44px;
  vertical-align: middle;
  border: 1px solid #eee;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  border-color: #ccc;
  background: #eee;
}

input[type='submit'] {
  padding: 13px 15px;
  height: 40px;
  line-height: 1;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.5px;
  border: none;
  cursor: pointer;
  color: #fff;
  background: #777;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
input[type='submit']:hover {
  background: #444;
}
select {
  padding: 8px;
  border: 2px solid #eee;
}
input[type='button'] {
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*------------------------------------------------------------------*/
/*	03) FONTELLO
/*------------------------------------------------------------------*/

/* @font-face {
  font-family: 'xcon';
  src: url('./font/xcon.eot?4965248');
  src: url('./font/xcon.eot?4965248#iefix') format('embedded-opentype'),
    url('./font/xcon.woff2?4965248') format('woff2'),
    url('./font/xcon.woff?4965248') format('woff'),
    url('./font/xcon.ttf?4965248') format('truetype'),
    url('./font/xcon.svg?4965248#xcon') format('svg');
  font-weight: normal;
  font-style: normal;
} */
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'xcon';
    src: url('../font/xcon.svg?4965248#xcon') format('svg');
  }
}
*/

[class^='xcon-']:before,
[class*=' xcon-']:before {
  font-family: 'xcon';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.xcon-phone:before {
  content: '\e800';
} /* 'î €' */
.xcon-mail:before {
  content: '\e801';
} /* 'î ' */
.xcon-globe:before {
  content: '\e802';
} /* 'î ‚' */
.xcon-glass:before {
  content: '\e803';
} /* 'î ƒ' */
.xcon-music:before {
  content: '\e804';
} /* 'î „' */
.xcon-search:before {
  content: '\e805';
} /* 'î …' */
.xcon-star:before {
  content: '\e806';
} /* 'î †' */
.xcon-star-empty:before {
  content: '\e807';
} /* 'î ‡' */
.xcon-heart:before {
  content: '\e808';
} /* 'î ˆ' */
.xcon-cancel-circled:before {
  content: '\e809';
} /* 'î ‰' */
.xcon-ok:before {
  content: '\e80a';
} /* 'î Š' */
.xcon-bookmark:before {
  content: '\e80b';
} /* 'î ‹' */
.xcon-thumbs-up:before {
  content: '\e80c';
} /* 'î Œ' */
.xcon-thumbs-down:before {
  content: '\e80d';
} /* 'î ' */
.xcon-download:before {
  content: '\e80e';
} /* 'î Ž' */
.xcon-upload:before {
  content: '\e80f';
} /* 'î ' */
.xcon-lock-open:before {
  content: '\e810';
} /* 'î ' */
.xcon-lock:before {
  content: '\e811';
} /* 'î ‘' */
.xcon-bell:before {
  content: '\e812';
} /* 'î ’' */
.xcon-location:before {
  content: '\e813';
} /* 'î “' */
.xcon-attention:before {
  content: '\e814';
} /* 'î ”' */
.xcon-cog:before {
  content: '\e815';
} /* 'î •' */
.xcon-resize-full:before {
  content: '\e816';
} /* 'î –' */
.xcon-resize-small:before {
  content: '\e817';
} /* 'î —' */
.xcon-resize-horizontal:before {
  content: '\e818';
} /* 'î ˜' */
.xcon-resize-vertical:before {
  content: '\e819';
} /* 'î ™' */
.xcon-basket:before {
  content: '\e81a';
} /* 'î š' */
.xcon-calendar:before {
  content: '\e81b';
} /* 'î ›' */
.xcon-wrench:before {
  content: '\e81c';
} /* 'î œ' */
.xcon-folder:before {
  content: '\e81d';
} /* 'î ' */
.xcon-folder-open:before {
  content: '\e81e';
} /* 'î ž' */
.xcon-volume-off:before {
  content: '\e81f';
} /* 'î Ÿ' */
.xcon-volume-up:before {
  content: '\e820';
} /* 'î  ' */
.xcon-volume-down:before {
  content: '\e821';
} /* 'î ¡' */
.xcon-clock:before {
  content: '\e822';
} /* 'î ¢' */
.xcon-zoom-out:before {
  content: '\e823';
} /* 'î £' */
.xcon-zoom-in:before {
  content: '\e824';
} /* 'î ¤' */
.xcon-down-dir:before {
  content: '\e825';
} /* 'î ¥' */
.xcon-up-dir:before {
  content: '\e826';
} /* 'î ¦' */
.xcon-left-dir:before {
  content: '\e827';
} /* 'î §' */
.xcon-right-dir:before {
  content: '\e828';
} /* 'î ¨' */
.xcon-down-open:before {
  content: '\e829';
} /* 'î ©' */
.xcon-left-open:before {
  content: '\e82a';
} /* 'î ª' */

.xcon-right-open:before {
  content: '\e82b';
} /* 'î «' */
.xcon-up-open:before {
  content: '\e82c';
} /* 'î ¬' */
.xcon-down-big:before {
  content: '\e82d';
} /* 'î ­' */
.xcon-left-big:before {
  content: '\e82e';
} /* 'î ®' */
.xcon-right-big:before {
  content: '\e82f';
} /* 'î ¯' */
.xcon-up-big:before {
  content: '\e830';
} /* 'î °' */
.xcon-play:before {
  content: '\e831';
} /* 'î ±' */
.xcon-spin3:before {
  content: '\e832';
} /* 'î ²' */
.xcon-play-circled2:before {
  content: '\e833';
} /* 'î ³' */
.xcon-pause:before {
  content: '\e834';
} /* 'î ´' */
.xcon-to-end:before {
  content: '\e835';
} /* 'î µ' */
.xcon-to-end-alt:before {
  content: '\e836';
} /* 'î ¶' */
.xcon-to-start:before {
  content: '\e837';
} /* 'î ·' */
.xcon-flash:before {
  content: '\e838';
} /* 'î ¸' */
.xcon-to-start-alt:before {
  content: '\e839';
} /* 'î ¹' */
.xcon-fast-fw:before {
  content: '\e83a';
} /* 'î º' */
.xcon-fast-bw:before {
  content: '\e83b';
} /* 'î »' */
.xcon-off:before {
  content: '\e83c';
} /* 'î ¼' */
.xcon-check:before {
  content: '\e83d';
} /* 'î ½' */
.xcon-comment:before {
  content: '\e83e';
} /* 'î ¾' */
.xcon-chat:before {
  content: '\e83f';
} /* 'î ¿' */
.xcon-forward:before {
  content: '\e840';
} /* 'î¡€' */
.xcon-eye:before {
  content: '\e841';
} /* 'î¡' */
.xcon-eye-off:before {
  content: '\e842';
} /* 'î¡‚' */
.xcon-star-half:before {
  content: '\e843';
} /* 'î¡ƒ' */
.xcon-heart-empty:before {
  content: '\e844';
} /* 'î¡„' */
.xcon-camera:before {
  content: '\e845';
} /* 'î¡…' */
.xcon-videocam:before {
  content: '\e846';
} /* 'î¡†' */
.xcon-picture:before {
  content: '\e847';
} /* 'î¡‡' */
.xcon-camera-alt:before {
  content: '\e848';
} /* 'î¡ˆ' */
.xcon-video:before {
  content: '\e849';
} /* 'î¡‰' */
.xcon-home:before {
  content: '\e84a';
} /* 'î¡Š' */
.xcon-pencil:before {
  content: '\e84b';
} /* 'î¡‹' */
.xcon-trash-empty:before {
  content: '\e84c';
} /* 'î¡Œ' */
.xcon-camera-1:before {
  content: '\e84d';
} /* 'î¡' */
.xcon-move:before {
  content: '\f047';
} /* 'ï‡' */
.xcon-bookmark-empty:before {
  content: '\f097';
} /* 'ï‚—' */
.xcon-twitter:before {
  content: '\f099';
} /* 'ï‚™' */
.xcon-facebook:before {
  content: '\f09a';
} /* 'ï‚š' */
.xcon-rss:before {
  content: '\f09e';
} /* 'ï‚ž' */
.xcon-resize-full-alt:before {
  content: '\f0b2';
} /* 'ï‚²' */
.xcon-gplus:before {
  content: '\f0d5';
} /* 'ïƒ•' */
.xcon-sort-down:before {
  content: '\f0dd';
} /* 'ïƒ' */
.xcon-sort-up:before {
  content: '\f0de';
} /* 'ïƒž' */
.xcon-mail-alt:before {
  content: '\f0e0';
} /* 'ïƒ ' */
.xcon-linkedin:before {
  content: '\f0e1';
} /* 'ïƒ¡' */
.xcon-comment-empty:before {
  content: '\f0e5';
} /* 'ïƒ¥' */
.xcon-chat-empty:before {
  content: '\f0e6';
} /* 'ïƒ¦' */
.xcon-bell-alt:before {
  content: '\f0f3';
} /* 'ïƒ³' */
.xcon-angle-double-left:before {
  content: '\f100';
} /* 'ï„€' */
.xcon-angle-double-right:before {
  content: '\f101';
} /* 'ï„' */
.xcon-angle-double-up:before {
  content: '\f102';
} /* 'ï„‚' */
.xcon-angle-double-down:before {
  content: '\f103';
} /* 'ï„ƒ' */
.xcon-angle-left:before {
  content: '\f104';
} /* 'ï„„' */
.xcon-angle-right:before {
  content: '\f105';
} /* 'ï„…' */
.xcon-angle-up:before {
  content: '\f106';
} /* 'ï„†' */
.xcon-angle-down:before {
  content: '\f107';
} /* 'ï„‡' */
.xcon-desktop:before {
  content: '\f108';
} /* 'ï„ˆ' */
.xcon-laptop:before {
  content: '\f109';
} /* 'ï„‰' */
.xcon-tablet:before {
  content: '\f10a';
} /* 'ï„Š' */
.xcon-mobile:before {
  content: '\f10b';
} /* 'ï„‹' */
.xcon-quote-left:before {
  content: '\f10d';
} /* 'ï„' */
.xcon-reply:before {
  content: '\f112';
} /* 'ï„’' */
.xcon-folder-empty:before {
  content: '\f114';
} /* 'ï„”' */
.xcon-folder-open-empty:before {
  content: '\f115';
} /* 'ï„•' */
.xcon-reply-all:before {
  content: '\f122';
} /* 'ï„¢' */
.xcon-star-half-alt:before {
  content: '\f123';
} /* 'ï„£' */
.xcon-direction:before {
  content: '\f124';
} /* 'ï„¤' */
.xcon-attention-alt:before {
  content: '\f12a';
} /* 'ï„ª' */
.xcon-mic:before {
  content: '\f130';
} /* 'ï„°' */
.xcon-mute:before {
  content: '\f131';
} /* 'ï„±' */
.xcon-play-circled:before {
  content: '\f144';
} /* 'ï…„' */
.xcon-ok-squared:before {
  content: '\f14a';
} /* 'ï…Š' */
.xcon-euro:before {
  content: '\f153';
} /* 'ï…“' */
.xcon-pound:before {
  content: '\f154';
} /* 'ï…”' */
.xcon-dollar:before {
  content: '\f155';
} /* 'ï…•' */
.xcon-thumbs-up-alt:before {
  content: '\f164';
} /* 'ï…¤' */
.xcon-thumbs-down-alt:before {
  content: '\f165';
} /* 'ï…¥' */
.xcon-youtube-play:before {
  content: '\f16a';
} /* 'ï…ª' */
.xcon-instagram:before {
  content: '\f16d';
} /* 'ï…­' */
.xcon-tumblr:before {
  content: '\f173';
} /* 'ï…³' */
.xcon-down:before {
  content: '\f175';
} /* 'ï…µ' */
.xcon-up:before {
  content: '\f176';
} /* 'ï…¶' */
.xcon-left:before {
  content: '\f177';
} /* 'ï…·' */
.xcon-right:before {
  content: '\f178';
} /* 'ï…¸' */
.xcon-apple:before {
  content: '\f179';
} /* 'ï…¹' */
.xcon-dribbble:before {
  content: '\f17d';
} /* 'ï…½' */
.xcon-vkontakte:before {
  content: '\f189';
} /* 'ï†‰' */
.xcon-mail-squared:before {
  content: '\f199';
} /* 'ï†™' */
.xcon-google:before {
  content: '\f1a0';
} /* 'ï† ' */
.xcon-behance:before {
  content: '\f1b4';
} /* 'ï†´' */
.xcon-paper-plane:before {
  content: '\f1d8';
} /* 'ï‡˜' */
.xcon-paper-plane-empty:before {
  content: '\f1d9';
} /* 'ï‡™' */
.xcon-share:before {
  content: '\f1e0';
} /* 'ï‡ ' */
.xcon-share-squared:before {
  content: '\f1e1';
} /* 'ï‡¡' */
.xcon-cc-visa:before {
  content: '\f1f0';
} /* 'ï‡°' */
.xcon-cc-mastercard:before {
  content: '\f1f1';
} /* 'ï‡±' */
.xcon-cc-discover:before {
  content: '\f1f2';
} /* 'ï‡²' */
.xcon-cc-amex:before {
  content: '\f1f3';
} /* 'ï‡³' */
.xcon-cc-paypal:before {
  content: '\f1f4';
} /* 'ï‡´' */
.xcon-cc-stripe:before {
  content: '\f1f5';
} /* 'ï‡µ' */
.xcon-bell-off:before {
  content: '\f1f6';
} /* 'ï‡¶' */
.xcon-bell-off-empty:before {
  content: '\f1f7';
} /* 'ï‡·' */
.xcon-toggle-off:before {
  content: '\f204';
} /* 'ïˆ„' */
.xcon-toggle-on:before {
  content: '\f205';
} /* 'ïˆ…' */
.xcon-pinterest:before {
  content: '\f231';
} /* 'ïˆ±' */
.xcon-hourglass-o:before {
  content: '\f250';
} /* 'ï‰' */
.xcon-trademark:before {
  content: '\f25c';
} /* 'ï‰œ' */
.xcon-registered:before {
  content: '\f25d';
} /* 'ï‰' */
.xcon-odnoklassniki:before {
  content: '\f263';
} /* 'ï‰£' */
.xcon-safari:before {
  content: '\f267';
} /* 'ï‰§' */
.xcon-chrome:before {
  content: '\f268';
} /* 'ï‰¨' */
.xcon-firefox:before {
  content: '\f269';
} /* 'ï‰©' */
.xcon-opera:before {
  content: '\f26a';
} /* 'ï‰ª' */
.xcon-internet-explorer:before {
  content: '\f26b';
} /* 'ï‰«' */
.xcon-vimeo:before {
  content: '\f27d';
} /* 'ï‰½' */

.clearfix {
  *zoom: 1;
  clear: both;
}
.clearfix:before,
.clearfix:after {
  display: table;
  line-height: 0;
  content: '';
}
.clearfix:after {
  clear: both;
}

/*------------------------------------------------------------------*/
/*	04) SKELETON OR GRID
/*------------------------------------------------------------------*/

.container {
  max-width: 1250px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding: 0px 40px;
  position: relative;
  clear: both;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.container.full {
  max-width: 100%;
}
.row {
  height: auto;
}
[class*='fl-col'],
.fl-sidebar {
  float: left;
  margin-right: 4%;
}
[class*='fl-col'].float-right {
  float: right;
}
[class*='fl-col'].last,
.fl-sidebar.last,
[class*='fl-col'].no-space,
.fl-col-12 {
  margin-right: 0px;
}

.fl-col-12 {
  width: 100%;
}
.fl-col-9 {
  width: 74%;
}
.fl-col-8 {
  width: 65.3333%;
}
.fl-col-6 {
  width: 48%;
}
.fl-col-4 {
  width: 30.6666%;
}
.fl-col-3 {
  width: 22%;
}
.fl-sidebar {
  width: 30.6666%;
}

.fl-col-9.no-space {
  width: 78%;
}
.fl-col-8.no-space {
  width: 69.3333%;
}
.fl-col-6.no-space {
  width: 52%;
}
.fl-col-4.no-space {
  width: 34.6666%;
}
.fl-col-3.no-space {
  width: 26%;
}

@media (max-width: 1040px) {
  .fl-sidebar {
    width: 100%;
    clear: both;
    margin-right: 0px;
    padding: 50px 0px;
  }
  .fl-col-8.fix {
    width: 100%;
  }
  .container {
    padding: 0px 20px;
  }
}

@media (max-width: 768px) {
  .fl-col-12 {
    width: 100%;
  }
  .fl-col-9 {
    width: 100%;
  }
  .fl-col-8 {
    width: 100%;
  }
  .fl-col-6 {
    width: 100%;
  }
  .fl-col-4 {
    width: 100%;
  }
  .fl-col-3 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0px 10px;
  }
}

/*------------------------------------------------------------------*/
/*	05) PERFECT SCROLLBAR
/*------------------------------------------------------------------*/

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto;
}
.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr;
}
.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
}
.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}
.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px;
}
.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}
.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.75;
  filter: 'alpha(opacity=75)';
  -ms-filter: 'alpha(opacity=75)';
}
.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0;
}
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
}
.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}
.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}
.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}
.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center;
}
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_dragger
  .mCSB_dragger_bar {
  width: 12px;
}
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail {
  width: 8px;
}
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonUp {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}
.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}
.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}
.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}
.mCustomScrollBox
  + .mCSB_scrollTools
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_dragger
  .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto;
}
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}
.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}
.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden
  + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}
.mCS-dir-rtl
  > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
  > .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden
  + .mCSB_scrollTools
  ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}
.mCS-dir-rtl
  > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
  > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}
.mCS-dir-rtl
  > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
  > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden
  + .mCSB_scrollTools
  ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}
.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px;
}
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
}
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
}
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
  > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0;
}
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
  > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-transition: opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s,
    margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s,
    margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
    opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s,
    margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s,
    margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
    opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s,
    margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s,
    margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
    opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s,
    margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s,
    margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
    opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: 'alpha(opacity=0)';
  -ms-filter: 'alpha(opacity=0)';
}
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCustomScrollbar
  > .mCustomScrollBox
  > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar
  > .mCustomScrollBox
  ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: 1;
  filter: 'alpha(opacity=100)';
  -ms-filter: 'alpha(opacity=100)';
}
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: 'alpha(opacity=40)';
  -ms-filter: 'alpha(opacity=40)';
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: 'alpha(opacity=75)';
  -ms-filter: 'alpha(opacity=75)';
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: 'alpha(opacity=85)';
  -ms-filter: 'alpha(opacity=85)';
}
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: 'alpha(opacity=90)';
  -ms-filter: 'alpha(opacity=90)';
}
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight,
.mCSB_scrollTools .mCSB_buttonUp {
  /* background-image: url(mCSB_buttons.png); */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: 'alpha(opacity=40)';
  -ms-filter: 'alpha(opacity=40)';
}
.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
}
.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
}
.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
}
.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
}
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover,
.mCSB_scrollTools .mCSB_buttonUp:hover {
  opacity: 0.75;
  filter: 'alpha(opacity=75)';
  -ms-filter: 'alpha(opacity=75)';
}
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active,
.mCSB_scrollTools .mCSB_buttonUp:active {
  opacity: 0.9;
  filter: 'alpha(opacity=90)';
  -ms-filter: 'alpha(opacity=90)';
}
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}
.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}
.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}
.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}
.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}
.mCS-light-2.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}
.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0;
}
.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px;
}
.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px;
}
.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark-2.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0;
}
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}
.mCS-light-thick.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}
.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark-thick.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px;
}
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
}
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto;
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark-thin.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px;
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px;
}
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px;
}
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal
  .mCSB_dragger
  .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0;
}
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_dragger
  .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_dragger
  .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0;
}
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail {
  width: 4px;
}
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_dragger
  .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_dragger
  .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px;
}
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
}
.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px;
}
.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px;
}
.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px;
}
.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px;
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-rounded-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px;
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px;
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px;
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: 'alpha(opacity=30)';
  -ms-filter: 'alpha(opacity=30)';
}
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=);
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, rgba(255, 255, 255, 0.5)),
    color-stop(100%, rgba(255, 255, 255, 0))
  );
  background-image: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
}
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(255, 255, 255, 0.5)),
    color-stop(100%, rgba(255, 255, 255, 0))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5) 0,
    rgba(255, 255, 255, 0) 100%
  );
}
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px;
}
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px;
}
.mCS-3d-dark.mCSB_scrollTools,
.mCS-3d.mCSB_scrollTools {
  opacity: 1;
  filter: 'alpha(opacity=30)';
  -ms-filter: 'alpha(opacity=30)';
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5),
    inset -1px 0 1px rgba(255, 255, 255, 0.2);
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
}
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5),
    inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}
.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}
.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}
.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}
.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools {
  opacity: 1;
  filter: 'alpha(opacity=30)';
  -ms-filter: 'alpha(opacity=30)';
}
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
  right: 1px;
}
.mCS-3d-thick-dark.mCSB_scrollTools_vertical,
.mCS-3d-thick.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1),
    inset 0 0 14px rgba(0, 0, 0, 0.5);
}
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal,
.mCS-3d-thick.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1),
    inset 0 0 14px rgba(0, 0, 0, 0.5);
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  height: 12px;
  width: auto;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1),
    inset 0 0 14px rgba(0, 0, 0, 0.2);
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4),
    inset -1px 0 0 rgba(0, 0, 0, 0.2);
}
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4),
    inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #777;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0;
}
.mCustomScrollBox.mCS-minimal
  + .mCSB_scrollTools
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark
  + .mCSB_scrollTools
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  left: 0;
  right: auto;
}
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}
.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: 'alpha(opacity=20)';
  -ms-filter: 'alpha(opacity=20)';
}
.mCS-minimal.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: 'alpha(opacity=50)';
  -ms-filter: 'alpha(opacity=50)';
}
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: 'alpha(opacity=20)';
  -ms-filter: 'alpha(opacity=20)';
}
.mCS-minimal-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: 'alpha(opacity=50)';
  -ms-filter: 'alpha(opacity=50)';
}
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
}
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail {
  width: 12px;
}
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_dragger.mCSB_dragger_onDrag_expanded
  + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
  .mCSB_draggerContainer:hover
  .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}
.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}
.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}
.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}
.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark-3.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}
.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}
.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}
.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}
.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0;
}
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-inset-2-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2);
}
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
}
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
}
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-inset-3.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}
.mCS-inset-3-dark.mCSB_scrollTools
  .mCSB_dragger.mCSB_dragger_onDrag
  .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}

/*------------------------------------------------------------------*/
/*	06) MAGNIFIC POPUP
/*------------------------------------------------------------------*/

.mfp-bg,
.mfp-wrap {
  position: fixed;
  left: 0;
  top: 0;
}
.mfp-bg,
.mfp-container,
.mfp-wrap {
  height: 100%;
  width: 100%;
}
.mfp-arrow:after,
.mfp-arrow:before,
.mfp-container:before,
.mfp-figure:after {
  content: '';
}
.mfp-bg {
  z-index: 1042;
  overflow: hidden;
  background: #0b0b0b;
  opacity: 0.8;
}
.mfp-wrap {
  z-index: 1043;
  outline: 0 !important;
  -webkit-backface-visibility: hidden;
}
.mfp-container {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}
.mfp-container:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
  display: none;
}
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}
.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
  width: 100%;
  cursor: auto;
}
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
  cursor: auto;
}
.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.mfp-loading.mfp-figure {
  display: none;
}
.mfp-hide {
  display: none !important;
}
.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #ccc;
}
.mfp-close,
.mfp-preloader a:hover {
  color: #fff;
}
.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
  display: none;
}
button.mfp-arrow,
button.mfp-close {
  overflow: visible;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: 0;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
.mfp-figure:after,
.mfp-iframe-scaler iframe {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:focus,
.mfp-close:hover {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}
.mfp-close-btn-in .mfp-close {
  color: #333;
}
.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}
.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}
.mfp-figure,
img.mfp-img {
  line-height: 0;
}
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: -55px 0 0;
  top: 50%;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:focus,
.mfp-arrow:hover {
  opacity: 1;
}
.mfp-arrow:after,
.mfp-arrow:before {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}
.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}
.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}
.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-image-holder .mfp-content,
img.mfp-img {
  max-width: 100%;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}
.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
}
.mfp-figure:after,
img.mfp-img {
  width: auto;
  height: auto;
  display: block;
}
img.mfp-img {
  box-sizing: border-box;
  padding: 40px 0;
  margin: 0 auto;
}
.mfp-figure:after {
  top: 40px;
  bottom: 40px;
  right: 0;
  z-index: -1;
  background: #444;
}
.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}
.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}
.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}
@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

/*------------------------------------------------------------------*/
/*	07) NAV ANCHOR 
/*------------------------------------------------------------------*/

.anchor_nav .current a {
  transition: all 0.3s ease;
}

/*------------------------------------------------------------------*/
/*	08) ANIMATION
/*------------------------------------------------------------------*/

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: 0.75s;
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  transform-origin: top center;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes wobble {
  from {
    transform: none;
  }

  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: none;
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  from,
  11.1%,
  to {
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  animation-name: jello;
  transform-origin: center;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in;
  }

  to {
    transform: perspective(400px);
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.flipOutX {
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }

  to {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    transform: skewX(-5deg);
    opacity: 1;
  }

  to {
    transform: none;
    opacity: 1;
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    transform-origin: center;
    opacity: 1;
  }

  to {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.jackInTheBox {
  animation-name: jackInTheBox;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.rollIn {
  animation-name: rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;

    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutDown {
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center;
  }
}

.zoomOutLeft {
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center;
  }
}

.zoomOutRight {
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutUp {
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

/*------------------------------------------------------------------*/
/*	09) OWL CAROUSEL
/*------------------------------------------------------------------*/
.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  width: auto;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url(owl.video.play.png) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.spinner {
  width: 70px;
  height: 70px;
  background-color: #fff;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.vegas-overlay,
.vegas-slide,
.vegas-slide-inner,
.vegas-timer,
.vegas-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border: none;
  padding: 0;
  margin: 0;
}
.vegas-overlay {
  opacity: 0.5;
  /* background: url(overlays/02.png) center center; */
}
.vegas-timer {
  top: auto;
  bottom: 0;
  height: 2px;
}
.vegas-timer-progress {
  width: 0;
  height: 100%;
  background: #fff;
  -webkit-transition: width ease-out;
  transition: width ease-out;
}
.vegas-timer-running .vegas-timer-progress {
  width: 100%;
}
.vegas-slide,
.vegas-slide-inner {
  margin: 0;
  padding: 0;
  background: center center no-repeat;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform, opacity;
}
body .vegas-container {
  overflow: hidden !important;
  position: relative;
}
.vegas-video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}
body.vegas-container {
  overflow: auto;
  position: static;
  z-index: -2;
}
body.vegas-container > .vegas-overlay,
body.vegas-container > .vegas-slide,
body.vegas-container > .vegas-timer {
  position: absolute;
  z-index: -1;
}
:root body.vegas-container > .vegas-overlay,
:root body.vegas-container > .vegas-slide,
_::full-page-media,
_:future {
  bottom: -76px;
}
.vegas-transition-blur,
.vegas-transition-blur2 {
  opacity: 0;
  -webkit-filter: blur(32px);
  filter: blur(32px);
}
.vegas-transition-blur-in,
.vegas-transition-blur2-in {
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
}
.vegas-transition-blur2-out {
  opacity: 0;
}
.vegas-transition-burn,
.vegas-transition-burn2 {
  opacity: 0;
  -webkit-filter: contrast(1000%) saturate(1000%);
  filter: contrast(1000%) saturate(1000%);
}
.vegas-transition-burn-in,
.vegas-transition-burn2-in {
  opacity: 1;
  -webkit-filter: contrast(100%) saturate(100%);
  filter: contrast(100%) saturate(100%);
}
.vegas-transition-burn2-out {
  opacity: 0;
  -webkit-filter: contrast(1000%) saturate(1000%);
  filter: contrast(1000%) saturate(1000%);
}
.vegas-transition-fade,
.vegas-transition-fade2 {
  opacity: 0;
}
.vegas-transition-fade-in,
.vegas-transition-fade2-in {
  opacity: 1;
}
.vegas-transition-fade2-out {
  opacity: 0;
}
.vegas-transition-flash,
.vegas-transition-flash2 {
  opacity: 0;
  -webkit-filter: brightness(25);
  filter: brightness(25);
}
.vegas-transition-flash-in,
.vegas-transition-flash2-in {
  opacity: 1;
  -webkit-filter: brightness(1);
  filter: brightness(1);
}
.vegas-transition-flash2-out {
  opacity: 0;
  -webkit-filter: brightness(25);
  filter: brightness(25);
}
.vegas-transition-negative,
.vegas-transition-negative2 {
  opacity: 0;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.vegas-transition-negative-in,
.vegas-transition-negative2-in {
  opacity: 1;
  -webkit-filter: invert(0);
  filter: invert(0);
}
.vegas-transition-negative2-out {
  opacity: 0;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.vegas-transition-slideDown,
.vegas-transition-slideDown2 {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.vegas-transition-slideDown-in,
.vegas-transition-slideDown2-in {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.vegas-transition-slideDown2-out {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.vegas-transition-slideLeft,
.vegas-transition-slideLeft2 {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.vegas-transition-slideLeft-in,
.vegas-transition-slideLeft2-in {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.vegas-transition-slideLeft2-out,
.vegas-transition-slideRight,
.vegas-transition-slideRight2 {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.vegas-transition-slideRight-in,
.vegas-transition-slideRight2-in {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.vegas-transition-slideRight2-out {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.vegas-transition-slideUp,
.vegas-transition-slideUp2 {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.vegas-transition-slideUp-in,
.vegas-transition-slideUp2-in {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.vegas-transition-slideUp2-out {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.vegas-transition-swirlLeft,
.vegas-transition-swirlLeft2 {
  -webkit-transform: scale(2) rotate(35deg);
  transform: scale(2) rotate(35deg);
  opacity: 0;
}
.vegas-transition-swirlLeft-in,
.vegas-transition-swirlLeft2-in {
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
  opacity: 1;
}
.vegas-transition-swirlLeft2-out,
.vegas-transition-swirlRight,
.vegas-transition-swirlRight2 {
  -webkit-transform: scale(2) rotate(-35deg);
  transform: scale(2) rotate(-35deg);
  opacity: 0;
}
.vegas-transition-swirlRight-in,
.vegas-transition-swirlRight2-in {
  -webkit-transform: scale(1) rotate(0);
  transform: scale(1) rotate(0);
  opacity: 1;
}
.vegas-transition-swirlRight2-out {
  -webkit-transform: scale(2) rotate(35deg);
  transform: scale(2) rotate(35deg);
  opacity: 0;
}
.vegas-transition-zoomIn,
.vegas-transition-zoomIn2 {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.vegas-transition-zoomIn-in,
.vegas-transition-zoomIn2-in {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.vegas-transition-zoomIn2-out,
.vegas-transition-zoomOut,
.vegas-transition-zoomOut2 {
  -webkit-transform: scale(2);
  transform: scale(2);
  opacity: 0;
}
.vegas-transition-zoomOut-in,
.vegas-transition-zoomOut2-in {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.vegas-transition-zoomOut2-out {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.vegas-animation-kenburns {
  -webkit-animation: kenburns ease-out;
  animation: kenburns ease-out;
}
@-webkit-keyframes kenburns {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes kenburns {
  0% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.vegas-animation-kenburnsDownLeft {
  -webkit-animation: kenburnsDownLeft ease-out;
  animation: kenburnsDownLeft ease-out;
}
@-webkit-keyframes kenburnsDownLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, -10%);
    transform: scale(1.5) translate(10%, -10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes kenburnsDownLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, -10%);
    transform: scale(1.5) translate(10%, -10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
.vegas-animation-kenburnsDownRight {
  -webkit-animation: kenburnsDownRight ease-out;
  animation: kenburnsDownRight ease-out;
}
@-webkit-keyframes kenburnsDownRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, -10%);
    transform: scale(1.5) translate(-10%, -10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes kenburnsDownRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, -10%);
    transform: scale(1.5) translate(-10%, -10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
.vegas-animation-kenburnsDown {
  -webkit-animation: kenburnsDown ease-out;
  animation: kenburnsDown ease-out;
}
@-webkit-keyframes kenburnsDown {
  0% {
    -webkit-transform: scale(1.5) translate(0, -10%);
    transform: scale(1.5) translate(0, -10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes kenburnsDown {
  0% {
    -webkit-transform: scale(1.5) translate(0, -10%);
    transform: scale(1.5) translate(0, -10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
.vegas-animation-kenburnsLeft {
  -webkit-animation: kenburnsLeft ease-out;
  animation: kenburnsLeft ease-out;
}
@-webkit-keyframes kenburnsLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, 0);
    transform: scale(1.5) translate(10%, 0);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes kenburnsLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, 0);
    transform: scale(1.5) translate(10%, 0);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
.vegas-animation-kenburnsRight {
  -webkit-animation: kenburnsRight ease-out;
  animation: kenburnsRight ease-out;
}
@-webkit-keyframes kenburnsRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, 0);
    transform: scale(1.5) translate(-10%, 0);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes kenburnsRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, 0);
    transform: scale(1.5) translate(-10%, 0);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
.vegas-animation-kenburnsUpLeft {
  -webkit-animation: kenburnsUpLeft ease-out;
  animation: kenburnsUpLeft ease-out;
}
@-webkit-keyframes kenburnsUpLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, 10%);
    transform: scale(1.5) translate(10%, 10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes kenburnsUpLeft {
  0% {
    -webkit-transform: scale(1.5) translate(10%, 10%);
    transform: scale(1.5) translate(10%, 10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
.vegas-animation-kenburnsUpRight {
  -webkit-animation: kenburnsUpRight ease-out;
  animation: kenburnsUpRight ease-out;
}
@-webkit-keyframes kenburnsUpRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, 10%);
    transform: scale(1.5) translate(-10%, 10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes kenburnsUpRight {
  0% {
    -webkit-transform: scale(1.5) translate(-10%, 10%);
    transform: scale(1.5) translate(-10%, 10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
.vegas-animation-kenburnsUp {
  -webkit-animation: kenburnsUp ease-out;
  animation: kenburnsUp ease-out;
}
@-webkit-keyframes kenburnsUp {
  0% {
    -webkit-transform: scale(1.5) translate(0, 10%);
    transform: scale(1.5) translate(0, 10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
@keyframes kenburnsUp {
  0% {
    -webkit-transform: scale(1.5) translate(0, 10%);
    transform: scale(1.5) translate(0, 10%);
  }
  100% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
}
/*# sourceMappingURL=vegas.min.css.map */

/*------------------------------------------------------------------*/
/*	09) LETTER EFFECT
/*------------------------------------------------------------------*/

.letter-effect {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* Text styles */
.title {
  margin: 0;
  font-size: 5vw;
  position: relative;
  color: #ffffff;
  line-height: 1.3;
  font-weight: 700;
  letter-spacing: -1px;
  font-family: 'Montserrat';
}
.slide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 10px;
}
.slide:not(.current) {
  pointer-events: none;
  opacity: 0;
}
