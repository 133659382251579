html {
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
}
body {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  font-weight: 400;
}
svg {
  fill: currentcolor;
  width: 15px;
  height: 15px;
}
img.svg {
  width: 15px;
  height: 15px;
}
.nn_tm_wrapper_all,
.nn_tm_section,
.nn_tm_content {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
#ripple {
  position: relative;
  z-index: 0;
}
.nn_tm_content {
  position: relative;
  z-index: 2;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #333;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #333;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #333;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #333;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #333;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #333;
}

.nn_tm_resize-right {
  position: fixed;
  top: 77%;
  left: 0%;
  text-align: center;
  line-height: 30px;
  background-color: #e3872d;
  display: inline-block;
  text-decoration: none;
  z-index: 200;
  width: 30px;
  height: 30px;
  opacity: 0;
  cursor: pointer;
}
.nn_tm_resize-right i {
  color: #fff;
  transform: rotate(0deg);
  position: absolute;
  left: 9px;
  top: 8px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nn_tm_resize-right:hover {
  background-color: #e3882db6;
}
