.nn_tm_services_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #fff;
  margin-bottom: 70px;
  text-align: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_services_wrap .nn_tm_title_holder_wrap {
  margin-bottom: 93px;
}
.nn_tm_services_wrap .list_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_services_wrap .list_wrap ul {
  margin: 0px 0px 0px -30px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nn_tm_services_wrap .list_wrap ul li {
  margin: 0px 0px 30px 0px;
  float: left;
  width: 33.3333%;
  padding-left: 30px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_services_wrap .list_wrap ul li .inner {
  width: 100%;
  height: 280px;
  clear: both;
  float: left;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 40px 40px 33px 40px;
  top: 0px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_services_wrap .list_wrap ul li .inner:hover {
  top: -5px;
}
.nn_tm_services_wrap .list_wrap ul li .inner:hover .svg {
  // fill: rgb(227, 135, 45);
  filter: invert(0.4) sepia(1) saturate(4) hue-rotate(334.8deg) brightness(0.96);
}
.nn_tm_services_wrap .list_wrap ul li .icon {
  margin-bottom: 21px;
}
.nn_tm_services_wrap .list_wrap ul li .icon .svg {
  width: 40px;
  height: 40px;
  color: #e3872d;
}
.nn_tm_services_wrap .list_wrap ul li .title_service {
  margin-bottom: 17px;
}
.nn_tm_services_wrap .list_wrap ul li .title_service h3 {
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  line-height: 1;
}
