.nn_tm_leftpart_wrap {
  width: 300px;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #041230;
  z-index: 100;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nn_tm_leftpart_wrap.hide {
  left: -300px;
}
.nn_tm_leftpart_wrap.opened {
  left: -300px;
}
.nn_tm_leftpart_wrap .logo_wrap {
  background-color: #041230;
  padding: 50px 20px;
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_leftpart_wrap .logo_wrap img {
  width: 50%;
}
.nn_tm_leftpart_wrap .menu_list_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 0px 20px 0px 60px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_leftpart_wrap .menu_list_wrap ul {
  margin: 0px;
  list-style-type: none;
}
.nn_tm_leftpart_wrap .menu_list_wrap ul li {
  margin: 0px;
}
.nn_tm_leftpart_wrap .menu_list_wrap ul li:last-child {
  margin-bottom: 0px;
}
.nn_tm_leftpart_wrap .menu_list_wrap ul li a {
  text-decoration: none;
  color: #fff;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-bottom: 19px;
  cursor: pointer;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}
.nn_tm_leftpart_wrap .menu_list_wrap ul li a:before {
  position: absolute;
  content: '';
  height: 2px;
  width: 0px;
  background-color: #e3872d;
  top: 14px;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 10px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nn_tm_leftpart_wrap .menu_list_wrap ul li a:hover {
  color: #e3872d;
}
.nn_tm_leftpart_wrap .menu_list_wrap ul li a:hover:before {
  width: 35px;
}
.nn_tm_leftpart_wrap .leftpart_bottom {
  position: absolute;
  left: 60px;
  bottom: 38px;
}
.nn_tm_leftpart_wrap .leftpart_bottom .social_wrap {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
  left: 70%;
  margin-bottom: 10px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_leftpart_wrap .leftpart_bottom .social_wrap ul {
  margin: 0px;
  list-style-type: none;
}
.nn_tm_leftpart_wrap .leftpart_bottom .social_wrap ul li {
  margin: 0px 10px 0px 0px;
  display: inline-block;
}
.nn_tm_leftpart_wrap .leftpart_bottom .social_wrap ul li:last-child {
  margin-right: 0px;
}
.nn_tm_leftpart_wrap .leftpart_bottom .social_wrap ul li a {
  text-decoration: none;
  color: #fff;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nn_tm_leftpart_wrap .leftpart_bottom .social_wrap ul li a:hover {
  color: #e3872d;
}
.nn_tm_leftpart_wrap .nn_tm_resize {
  position: absolute;
  right: 0px;
  bottom: 20%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #e3872d;
  display: inline-block;
  text-decoration: none;
  z-index: 200;
  cursor: pointer;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nn_tm_leftpart_wrap .nn_tm_resize:hover {
  background-color: #e3882db6;
}
.nn_tm_leftpart_wrap .nn_tm_resize.opened {
  right: -30px;
}
.nn_tm_leftpart_wrap .nn_tm_resize i {
  color: #fff;
  transform: rotate(0deg);
  position: absolute;
  left: 10px;
  top: 8px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nn_tm_leftpart_wrap .nn_tm_resize i.opened {
  transform: rotate(-180deg);
}
