.nn_tm_footer_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: left;
  background-color: #041230;
  padding: 50px 0px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_footer_wrap p {
  color: #fff;
}
.nn_tm_footer_wrap .container {
  // text-align: right;
}
