.nn_tm_mobile_header_wrap {
  width: 100%;
  height: 1rem;
  position: fixed;
  z-index: 11111111;
  left: 0px;
  top: 0px;
  display: none;
}
.nn_tm_mobile_header_wrap .main_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #041230;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_trigger {
  width: auto;
}
.nn_tm_trigger .hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.nn_tm_trigger .hamburger-box {
  width: 30px;
  height: 18px;
  display: inline-block;
  position: relative;
}
.nn_tm_trigger .hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.nn_tm_trigger .hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: #333333;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.nn_tm_trigger .hamburger-inner::before,
.nn_tm_trigger .hamburger-inner::after {
  content: '';
  display: block;
}
.nn_tm_trigger .hamburger-inner::before {
  top: -8px;
}
.nn_tm_trigger .hamburger-inner::after {
  bottom: -10px;
}
.nn_tm_trigger .hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.nn_tm_trigger .hamburger--collapse-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.nn_tm_trigger .hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.nn_tm_trigger .hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.nn_tm_trigger .hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.nn_tm_trigger .hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.nn_tm_trigger .hamburger {
  padding: 0px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.nn_tm_trigger .hamburger-box {
  display: block;
}
.nn_tm_trigger .hamburger .hamburger-inner::before,
.nn_tm_trigger .hamburger .hamburger-inner::after,
.nn_tm_trigger .hamburger .hamburger-inner {
  background-color: #fff;
  width: 30px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.nn_tm_mobile_menu_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: rgba(4, 18, 48, 0.97);
  padding: 30px 20px;
  text-align: center;
  // display: none;
  // transform: translateY(-100vh);
  // transform: translateY(0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  border-top: 1px solid rgba(0, 0, 0, 0.09);

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  transition: transform 0.8s ease-in-out;
}
.nn_tm_mobile_menu_wrap .mob_menu {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_mobile_menu_wrap .mob_menu > ul {
  margin: 0px;
  list-style-type: none;
}
.nn_tm_mobile_menu_wrap .mob_menu > ul > li {
  margin: 0px 0px 5px 0px;
}
.nn_tm_mobile_menu_wrap .mob_menu > ul > li:last-child {
  margin-bottom: 0px;
}
.nn_tm_mobile_menu_wrap .mob_menu > ul > li a {
  text-decoration: none;
  color: #fff;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 19px;
  cursor: pointer;
}
.nn_tm_mobile_header_wrap .logo img {
  max-width: 27%;
}
