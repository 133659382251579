.nn_tm_contact_wrap_all {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #fff;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_contact_wrap_all .leftbox {
  width: 40%;
  height: auto;
  float: left;
  padding-right: 40px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_contact_wrap_all .leftbox .short_info_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_contact_wrap_all .leftbox .short_info_wrap ul {
  margin: 0px;
  list-style-type: none;
}
.nn_tm_contact_wrap_all .leftbox .short_info_wrap ul li {
  margin: 0px 0px 15px 0px;
}
.nn_tm_contact_wrap_all .leftbox .short_info_wrap ul li label {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 13px;
  color: #000;
}
.nn_tm_contact_wrap_all .leftbox .short_info_wrap ul li span {
  padding-left: 5px;
}
.nn_tm_contact_wrap_all .leftbox .short_info_wrap ul li a {
  text-decoration: none;
  color: #000;
}
.nn_tm_contact_wrap_all .rightbox {
  width: 60%;
  height: auto;
  float: right;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_contact_wrap {
  max-width: 830px;
  height: auto;
  margin: 0px auto;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_contact_wrap .subtitle {
  text-align: center;
  margin-bottom: 69px;
}
.nn_tm_contact_wrap .subtitle p {
  color: #333;
}
.nn_tm_contact_wrap .main_input_wrap {
  margin-bottom: 75px;
  position: relative;
  top: -24px;
}
.nn_tm_contact_wrap .main_input_wrap,
.nn_tm_contact_wrap .main_input_wrap .wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_contact_wrap .main_input_wrap .wrap {
  margin-bottom: 20px;
}
.nn_tm_contact_wrap .main_input_wrap .wrap input {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(10, 4, 37, 1);
  color: #333;
  padding-left: 0px !important;
}
.nn_tm_contact_wrap .main_input_wrap .wrap input:focus {
  outline: none;
}
.nn_tm_contact_wrap .main_input_wrap .wrap textarea {
  width: 100%;
  height: 80px;
  text-align: left;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(10, 4, 37, 1);
  color: #333;
  padding-left: 0px !important;
}
.nn_tm_contact_wrap .main_input_wrap .wrap textarea:focus {
  outline: none;
}
.nn_tm_contact_wrap .nn_tm_button a {
  text-decoration: none;
  color: #fff;
  background-color: #e3872d;
  font-weight: 600;
  font-family: 'Montserrat';
  padding: 12px 35px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.nn_tm_contact_wrap .nn_tm_button a:before {
  position: absolute;
  content: '';
  top: 0px;
  height: 100%;
  left: -25%;
  width: 0%;
  background-color: #041230;
  transform: skew(50deg);
  transition-duration: 0.6s;
  z-index: 1;
}
.nn_tm_contact_wrap .nn_tm_button a:hover:before {
  width: 170%;
}
.nn_tm_contact_wrap .nn_tm_button a span {
  position: relative;
  z-index: 2;
}
.nn_tm_contact_wrap .empty_notice {
  color: #bd0a0d;
  margin-bottom: 7px;
  display: none;
  text-align: left;
}
.nn_tm_contact_wrap .contact_error {
  color: rgba(255, 0, 4, 1);
  text-align: left;
}
.nn_tm_contact_wrap .returnmessage {
  color: #0064ff;
  margin-bottom: 7px;
  text-align: left;
}
