.nn_tm_hero_header_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 100px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_universal_box_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_universal_box_wrap .bg_wrap {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background-image: url('./assets/img/cover.jpg');
  background-size: cover;
}

.nn_tm_universal_box_wrap .overlay_color {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
}
.nn_tm_universal_box_wrap .content {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  z-index: 25;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_universal_box_wrap .overlay_color.hero {
  background-color: rgba(7, 23, 55, 0.8);
}
.nn_tm_universal_box_wrap .content.hero {
  height: 100vh;
}
.nn_tm_hero_header_wrap .inner_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  width: 100%;
  padding: 0px 20px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_hero_header_wrap .image_wrap {
  width: 200px;
  height: 200px;
  display: inline-block;
  margin-bottom: 38px;
}
.nn_tm_hero_header_wrap .image_wrap img {
  border-radius: 100%;
  border: 8px solid rgba(255, 255, 255, 0.5);
}
.nn_tm_hero_header_wrap .name_holder h3 {
  font-family: 'Montserrat';
  text-transform: uppercase;
  color: #fff;
  font-size: 50px;
  font-weight: 300;
}
.nn_tm_hero_header_wrap .name_holder h3 span {
  font-weight: 500;
  color: #e3872d;
}
.nn_tm_hero_header_wrap .text_typing {
  width: 100%;
  height: auto;
  float: left;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.nn_tm_hero_header_wrap .text_typing p {
  color: #fff;
  font-size: 24px;
  font-family: 'Montserrat';
}
.nn_tm_animation_text_word {
  font-weight: 600;
  line-height: 1;
  font-family: 'Montserrat';
}
.nn_tm_about_wrap .nn_tm_animation_text_word {
  color: #e3872d;
}
.nn_tm_arrow_wrap {
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);

  z-index: 55;
  cursor: pointer;
}
.nn_tm_arrow_wrap.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
.nn_tm_arrow_wrap a {
  text-decoration: none;
  color: #fff;
  font-size: 30px;
}
